.searchResultCard {
  border: 1px solid $neutral-100;
  border-radius: 20px;
  width: 100%;

  &:hover {
    border: 1px solid $background;
    background-color: $background;
    cursor: pointer;
  }

  & > button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: $space-20 $space-30;

    @include sm {
      justify-content: flex-start;
    }

    & > .P2 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > .P1 {
      color: $neutral-500;

      &::before {
        content: '|';
        margin-inline: $space-10;
      }
    }
  }
}
