.contentLarge {
  @include card;
}

.contentLargeButton {
  margin-left: auto;
  margin-top: $space-20;

  @include lg {
    margin-top: $space-30;
  }

  & > .P1 {
    color: $white;
  }
}
