.vacanciesAndCta {
  &__card {
    order: 1;
    grid-column: span 2 / span 2;

    @include lg {
      grid-column: span 4 / span 4;
    }
  }
}
