// MIXINS
@mixin color-arrow-icon($color) {
  &-small > svg > path {
    fill: $color !important;

    &:first-child {
      stroke: $color !important;
    }
  }

  &-big > svg > g > path {
    fill: $color !important;

    &:first-child {
      stroke: $color !important;
    }
  }
}

@mixin button-style {
  border-radius: 25px;
  min-height: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  @include md {
    height: 50px;
  }
}

@mixin circle-button-style($border-color, $bg-color) {
  border: 1px solid $border-color;
  background-color: $bg-color;
  width: 40px;

  @include md {
    width: 50px;
  }
}

@mixin button-colors($color, $background-color, $border-color) {
  color: $color;
  background-color: $background-color;
  border: 1px solid $border-color;
}

// STYLES
.button {
  @include button-style;
  padding: 5px 20px;

  &:disabled {
    cursor: not-allowed;
  }

  &__with-arrow {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  &__primary--a,
  &__primary--b,
  &__primary--c,
  &__secondary--a,
  &__secondary--b,
  &__tertiary--a,
  &__tertiary--b {
    font-family: Rubik-SemiBold;

    > * {
      font-family: Rubik-SemiBold;
    }
  }

  // PRIMARY BUTTON
  &__primary {
    &:disabled {
      @include button-colors($white, $neutral-600, $neutral-600);
    }

    &--a {
      @include button-colors(
        $white,
        var(--color-primary),
        var(--color-primary)
      );

      & .arrow__right {
        @include color-arrow-icon($white);
      }

      &:hover {
        @include button-colors(
          $white,
          var(--color-tertiary),
          var(--color-tertiary)
        );
      }
    }

    &--b {
      @include button-colors(var(--color-primary), $white, $white);

      & .arrow__right {
        @include color-arrow-icon(var(--color-primary));
      }

      &:hover {
        @include button-colors(
          $white,
          var(--color-primary),
          var(--color-primary)
        );

        & .arrow__right {
          @include color-arrow-icon($white);
        }
      }
    }

    &--c {
      @include button-colors(
        var(--color-primary),
        $white,
        var(--color-primary)
      );

      & .arrow__right {
        @include color-arrow-icon(var(--color-primary));
      }

      &:hover {
        @include button-colors(
          $white,
          var(--color-primary),
          var(--color-primary)
        );

        & .arrow__right {
          @include color-arrow-icon($white);
        }
      }
    }
  }

  // SECONDARY BUTTON
  &__secondary {
    &:disabled {
      @include button-colors($white, $neutral-600, $neutral-600);

      & .arrow__right {
        @include color-arrow-icon($white);
      }
    }

    &--a {
      @include button-colors(
        var(--color-primary),
        transparent,
        var(--color-primary)
      );

      & .arrow__right {
        @include color-arrow-icon(var(--color-primary));
      }

      &:hover {
        @include button-colors(
          $white,
          var(--color-tertiary),
          var(--color-tertiary)
        );

        & .arrow__right {
          @include color-arrow-icon($white);
        }
      }

      &:active {
        @include button-colors(
          $white,
          var(--color-primary),
          var(--color-primary)
        );

        & .arrow__right {
          @include color-arrow-icon($white);
        }
      }
    }

    &--b {
      @include button-colors($white, transparent, $white);

      & .arrow__right {
        @include color-arrow-icon($white);
      }

      &:hover {
        @include button-colors(var(--color-primary), $white, $white);

        & .arrow__right {
          @include color-arrow-icon(var(--color-primary));
        }
      }

      &:active {
        @include button-colors(var(--color-tertiary), $white, $white);

        & .arrow__right {
          @include color-arrow-icon(var(--color-tertiary));
        }
      }
    }
  }

  // TERTIARY BUTTON
  &__tertiary {
    &:disabled {
      color: $neutral-500;
    }

    &--a {
      @include button-colors(var(--color-primary), transparent, transparent);

      & .arrow__right {
        @include color-arrow-icon(var(--color-primary));
      }

      &:hover {
        @include button-colors(var(--color-tertiary), transparent, transparent);

        & .arrow__right {
          @include color-arrow-icon(var(--color-tertiary));
        }
      }
    }

    &--b {
      color: $white;
      & .arrow__right {
        @include color-arrow-icon($white);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // QUARTIARY BUTTON
  &__quartiary {
    &--a,
    &--b {
      & .arrow__right-big {
        display: grid;
        place-items: center;
        height: 40px;
        width: 40px;
        border-radius: 25px;

        @include md {
          height: 50px;
          width: 50px;
        }
      }
    }

    &--a {
      & .arrow__right {
        @include color-arrow-icon($white);

        &-big {
          background-color: var(--color-primary);
          border: 2px solid var(--color-primary);
        }
      }

      &:hover {
        text-decoration: underline;

        & .arrow__right-big {
          background-color: var(--color-tertiary);
          border: 2px solid var(--color-tertiary);
        }
      }
    }

    &--b {
      & .arrow__right {
        @include color-arrow-icon(var(--color-primary));

        &-big {
          background-color: transparent;
          border: 2px solid var(--color-primary);
        }
      }

      &:hover {
        text-decoration: underline;

        & .arrow__right {
          @include color-arrow-icon($white);

          &-big {
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
          }
        }
      }
    }
  }

  &__ray {
    &--a,
    &--b {
      background-color: $ray-primary;
      border: 1px solid $ray-primary;

      &:hover {
        background-color: $ray-primary-700;
        border: 1px solid $ray-primary-700;
      }
    }
  }

  &__rvdb {
    &--a,
    &--b {
      background-color: $rvdb-primary;
      border: 1px solid $rvdb-primary;

      &:hover {
        background-color: $rvdb-primary-800;
        border: 1px solid $rvdb-primary-800;
      }
    }
  }

  &__text {
    &--a,
    &--b {
      color: var(--color-primary);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__icon {
    &--a {
      @include button-style;
      @include circle-button-style(var(--color-primary), transparent);
    }

    &--b {
      @include button-style;
      @include circle-button-style(var(--color-primary), var(--color-primary));

      & > span > svg > path {
        fill: $white;
      }
    }
  }

  &__loadMore {
    display: flex;
    width: fit-content;
    border: 1px solid $rvdb-secondary;
    color: $rvdb-secondary;

    & > span > svg > path {
      fill: $rvdb-secondary;
    }
  }
}

.dotButton {
  &--active > span > svg {
    fill: var(--color-primary) !important;
  }

  &--inactive > span > svg {
    fill: transparent !important;
  }
}

.vacancyButton {
  width: 50%;
  height: 60px;

  &:first-of-type {
    border-radius: 10px 0 0 10px;
  }

  &:last-of-type {
    border-radius: 0 10px 10px 0;
  }

  &--active {
    background-color: var(--color-primary);
    color: $white;
  }

  &--inactive {
    border: 2px solid var(--color-primary);

    background-color: $white;
    color: var(--color-primary);
  }
}

.showPasswordButton {
  position: absolute;
  width: 24px;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  margin-right: 16px;

  &:disabled {
    svg {
      path {
        fill: $neutral-500;
      }
    }

    &:hover {
      cursor: not-allowed;
    }
  }
}
