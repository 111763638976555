.deleteAccount,
.editAccount,
.accountPicture,
.emailPreferences {
  @include card;

  & ._form_29 > * {
    text-align: left !important;
  }
}

.accountPicture__pictureInput {
  display: none;
}
