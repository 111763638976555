.storyCardWithImg {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: $white;
  overflow: hidden;

  &--right,
  &--left {
    flex-direction: column;

    & > .link {
      width: 100%;

      @include md {
        width: 50%;
      }
    }

    &__img {
      display: flex;
      width: 100%;
      height: 100%;

      > span {
        width: 100% !important;
      }
    }
  }

  &--right {
    @include md {
      flex-direction: row-reverse;
    }
  }

  &--left {
    @include md {
      flex-direction: row;
    }
  }

  &__img {
    width: 100%;
    border-radius: 20px 20px 0 0;
    overflow: hidden;

    > span {
      width: 100% !important;
    }
  }

  &__text {
    justify-content: space-between;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 29px;
    padding: 20px;

    @include md {
      padding: $space-30 $space-40;
    }

    > .H2 {
      color: $black;
      @include ellipsisText(2);
    }

    > .P1 {
      overflow: hidden;
      @include ellipsisText(3);
    }

    &-description > * {
      margin-bottom: 0;
    }
  }
}
