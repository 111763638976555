/* BREAKPOINTS */

// Small smartphone devices xs: >= 320px & <= 576px
$screen-xs-min: 320px;

// Small tablets and large smartphones (landscape view) sm: >= 576px & <= 768px
$screen-sm-min: 576px;

// Small tablets (portrait view) md: >= 768px & <= 992px
$screen-md-min: 768px;

// Tablets and small desktops lg: >= 992px & <= 1200px
$screen-lg-min: 992px;

// Large tablets and desktops xl: >= 1200px
$screen-xl-min: 1200px;

/*----------------------------------------------------------------*/

/* COLORS */
/* Neutrals */
$black: #464646;
$white: #ffffff;
$neutral-600: #6a6a6a;
$neutral-500: #858585;
$neutral-300: #a6a6a6;
$neutral-100: #e5e5e5;
$background: #ebf5f7;
$background-secondary: #f7f7f7;

/* Primary RVDB */
$rvdb-primary: #09c591;
$rvdb-primary-500: #0be8ab;
$rvdb-primary-600: #0ad19a;
$rvdb-primary-700: #09ba89;
$rvdb-primary-800: #08a278;

/* Secondary RVDB */
$rvdb-secondary: #3488a9;
$rvdb-secondary-500: #71acc3;
$rvdb-secondary-600: #4894b2;
$rvdb-secondary-700: #2a6d87;
$rvdb-secondary-800: #245f76;

/* Primary RAY */
$ray-primary: #ff128e;
$ray-primary-600: #e61080;
$ray-primary-650: #cc0e72;
$ray-primary-700: #b30d63;
$ray-primary-800: #990b55;

/* Secondary RAY */
$ray-secondary: #ff6612;
$ray-secondary-500: #e65c10;

/* Gradients */
$rvdb-gradient: linear-gradient(90deg, $rvdb-secondary 0%, $rvdb-primary 100%);
$ray-gradient: linear-gradient(90deg, $ray-secondary 0%, $ray-primary 100%);

/* Success */
$success-light: $rvdb-primary-700;
$success-dark: #cef1e7;

/* Warning */
$warning-light: #ffe0d0;
$warning-dark: $ray-secondary-500;

/* Error */
$error-light: #ffd0e8;
$error-dark: #e61080;

/*----------------------------------------------------------------*/

/* SPACING */
$space-5: 5px;
$space-10: 10px;
$space-20: 20px;
$space-30: 30px;
$space-40: 40px;
$space-50: 50px;
$space-60: 60px;
$space-70: 70px;
$space-80: 80px;
$space-90: 90px;
$space-100: 100px;
$space-110: 110px;
$space-120: 120px;

/*----------------------------------------------------------------*/
