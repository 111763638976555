.accordion {
  max-width: 1280px;
  width: 100%;
  padding: $space-20;
  background-color: $white;
  border-radius: 20px;

  @include md {
    padding: $space-40;
  }

  &__item {
    min-height: 67px;
    border-top: 2px solid var(--color-primary);

    &:last-child {
      border-bottom: 2px solid var(--color-primary);
    }
  }
}

.item {
  &__question {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0 20px 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__answer {
    padding-top: 5px;
    padding-bottom: $space-40;
  }
}
