.organizationCard {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  @include md {
    border-radius: 20px;
  }

  &__img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    @include md {
      border-radius: 20px 20px 0 0;
    }

    > .imageWrapper {
      width: 100%;
    }
  }

  &__text {
    padding: $space-20 $space-20 $space-30 $space-20;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    @include md {
      padding: $space-30 $space-40;
    }

    & .P1 {
      @include ellipsisText(4);
    }
  }
}
