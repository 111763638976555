.ctaStandardRvdbGradientCard,
.ctaStandardRvdbWhiteBgCard {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: $space-40;

  & > .H2 {
    margin-bottom: $space-10;
  }

  & > .P1 {
    margin-bottom: $space-30;
  }

  & .button {
    margin-top: auto;
  }
}

.ctaStandardRvdbGradientCard {
  background-image: $rvdb-gradient;

  & > .P1,
  & > .H2 {
    color: $white;
  }
}

.ctaStandardRvdbWhiteBgCard {
  background-color: $white;
}

.ctaStandardRvdbTallWithBtnCard {
  border-radius: 20px;
  padding: $space-40;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > .button {
    margin-top: 20px;
  }
}

.ctaStandardRvdbTallCard {
  border-radius: 20px;
  padding: $space-40;
  background-color: $white;
}
