.vacanciesBanner {
  position: relative;
  border-radius: 20px;

  &__intro {
    grid-column: 1 / -1;

    @include lg {
      grid-row: 1 / 3;
      grid-column: 1 / 6;
    }
  }

  &__buttons {
    grid-column: 1 / -1;

    @include lg {
      grid-column: 6 / 13;
    }
  }

  &__search {
    height: 60px;
    width: 100%;
    grid-row: 2 / 3;
    grid-column: 1 / -1;

    @include lg {
      grid-column: 6 / 13;
    }
  }

  &__card {
    width: 100%;
    max-width: 1280px;
    position: absolute;
    margin-top: $space-30;
    border-radius: 20px;
    z-index: 1;
    background-color: $white;
    padding: $space-20;

    @include lg {
      padding: $space-30 $space-30 $space-30 $space-50;
      background-color: $white;
      margin-top: 0;
      top: 69%;
      width: max(100%, calc(100% - $space-80));
      margin: 0 auto;
    }
  }
}
