// this className targets an internal div in slick carousel library
.slick-track {
  display: flex !important;
  align-items: center;
}

.clientImage {
  display: flex;
  justify-content: center;
  align-items: center;
}
