.ctaStandardRayCard {
  display: flex;
  background: $white;
  border-radius: 20px;
  overflow: hidden;

  @include customBreakPoint(350px, 576px) {
    max-height: 300px;
  }

  &__img {
    display: flex;
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: 19px 19px 31px 19px;

    @include md {
      padding: 102px 26px 79px 40px;
    }

    > .H2 {
      @include text(22px, 30px, 0em, 300);
      margin-bottom: $space-30;
      color: $black;

      @include sm {
        @include text(60px, 71px, 0em, 300);
        color: $black;
      }

      @include lg {
        max-width: 10ch;
      }
    }

    > .P1 {
      max-width: 50ch;

      @include lg {
        max-width: 28ch;
      }
    }

    > .button {
      margin-top: auto;
    }
  }
}
