.drawer {
  &__content {
    width: 100%;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.MuiDrawer-paper {
  width: auto !important;
  height: 100%;
  min-height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 30px;
}
