.vacancyDetails {
  &__logo {
    > span > img {
      width: auto !important;
      height: auto !important;
      max-width: 256px !important;
      max-height: 145px !important;
      min-height: 0 !important;
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
  }
}