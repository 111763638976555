.ourTeamHeader {
  padding-top: $space-40;
  padding-bottom: $space-30;
  margin-bottom: $space-20;
  background-color: $white;
  border-radius: 0 0 10px 10px;
  margin-inline: auto;

  @include lg {
    padding-top: $space-100;
    margin-bottom: $space-80;
    padding-bottom: 0;
    background-color: transparent;
  }

  &__iconTitle {
    display: flex;
    gap: $space-10;
    align-items: center;
    grid-column: 1 / -1;
    margin-inline: $space-20;

    @include lg {
      grid-column: 1 / 5;
    }
  }

  &__searchBar {
    grid-column: 1 / -1;
    margin-inline: $space-20;
    width: auto !important;

    @include lg {
      grid-column: 5 / 13;
    }
  }
}
