.inputField {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  max-width: 1280px;

  @include md {
    gap: 15px;
  }

  &__field {
    display: flex;
    align-items: center;
    padding: 9px 10px 9px 10px;
    width: 100%;
    min-width: 200px;
    max-width: 1280px;
    min-height: 50px;
    background: $white;
    border: 1px solid #d6d6d6;
    border-radius: 3px;

    &::placeholder {
      padding-left: 3px;
      font-family: Rubik-Light;
      color: $neutral-500;
      @include text(16px, 19px, -0.2px, 300);
    }

    &:focus {
      outline-color: var(--color-primary);
    }
  }

  &__with-arrow {
    &__btn {
      border-radius: 25px;
    }

    &__arrow {
      position: absolute;
      top: 35%;
      right: 6%;

      &:hover {
        cursor: pointer;
      }

      & > span > svg > g > path {
        fill: var(--color-primary) !important;

        &:first-child {
          stroke: var(--color-primary) !important;
        }
      }
    }
  }

  > label {
    font-family: Rubik-Light;
    color: $black;
    @include text(16px, 22px, 0, 400);

    @include md {
      @include text(18px, 30px, 0, 400);
    }

    &.required::after {
      content: ' *';
      color: var(--color-primary);
    }
  }

  &__checkbox {
    min-width: 16px;
    min-height: 16px;
    border-radius: 4px;
    appearance: none;
    margin: 0 12px 0 0;
    background-color: $white;
    border: 1px solid var(--color-primary);

    &:checked {
      background-color: var(--color-primary);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.textarea {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  &__field {
    padding: 9px 10px;
    max-width: 1200px;
    min-height: 190px;
    width: 100%;
    height: 100%;
    background: $white;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    resize: none;

    &::placeholder {
      padding-left: 3px;
      font-family: Rubik-Light;
      color: #999999;
      @include text(16px, 19px, -0.2px, 300);
    }

    &:focus {
      outline-color: var(--color-primary);
    }
  }

  > label {
    font-family: Rubik-Light;
    color: $black;
    @include text(16px, 22px, 0, 400);

    @include md {
      @include text(18px, 30px, 0, 400);
    }

    &.required::after {
      content: ' *';
      color: var(--color-primary);
    }
  }
}

.uploadInput {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__label {
    font-family: Rubik-Light;
    color: $black;

    @include text(16px, 22px, 0, 400);

    @include md {
      @include text(18px, 30px, 0, 400);
    }

    &.required::after {
      content: ' *';
      color: var(--color-primary);
    }
  }

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 10px 1px 10px;
    width: 100%;
    min-width: 200px;
    max-width: 1280px;
    height: 100%;
    min-height: 50px;
    background: $white;
    border: 1px solid #d6d6d6;
    border-radius: 3px;

    > .H3 {
      padding-left: 3px;
      font-family: Rubik-Light;
      color: #999999;
      @include text(16px, 19px, -0.2px, 300);
    }
  }
}

.inputFieldSearch {
  position: relative;

  &__Btn {
    display: grid;
    place-items: center;
    position: absolute;
    right: 0;
    display: flex;
    height: 100%;
    color: var(--color-primary);

    &__icon {
      margin: 0 20px;

      & > .MuiCircularProgress-root {
        color: var(--color-primary);
      }
    }
  }

  & > input {
    border-radius: 30px;
  }
}

.passwordInputField {
  position: relative;
}

.phoneInput {
  @extend .inputField;

  &__container {
    background: $white;
    width: 100%;
    height: 100%;
    min-height: 50px;
    min-width: 200px;
    max-width: 1280px;
  }

  &__field {
    width: 100% !important;
    padding-left: 9px;
    min-height: 50px;
    border-radius: 3px;
    border: 1px solid #d6d6d6;
    font-size: 17px !important;
    line-height: 19px !important;

    &::placeholder {
      padding-left: 3px;
      font-family: Rubik-Light;
      color: #999999;
      @include text(16px, 19px, -0.2px, 300);
    }
  }

  &__country {
    padding: 9px 5px 9px 10px;
    margin-right: 0;
    border-radius: 10px 0 0 10px;

    > div {
      border-radius: 10px 0 0 10px;
    }
  }
}
