.locationCard {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  @include md {
    border-radius: 20px;
  }

  &__img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    > .imageWrapper {
      width: 100% !important;
    }

    & > span {
      width: 100% !important;
    }

    @include md {
      border-radius: 20px 20px 0 0;
    }
  }

  &__text {
    flex: 1;
    padding: $space-20 $space-40;
    display: flex;
    flex-direction: column;
  }
}
