.divider {
  height: 2px;
  width: 100%;
  margin-top: $space-20;
  margin-bottom: $space-20;
  background-color: var(--color-primary);

  &--black {
    background-color: $black;
  }

  &--white {
    background-color: $white;
  }
}
