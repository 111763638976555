.homepageSlogan {
  position: absolute;
  display: inline-block;
  max-width: min-content;
  top: $space-40;
  left: $space-40;
  color: $white;

  @include lg {
    top: 65px;
    left: $space-80;
  }
}
