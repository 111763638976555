.filters {
  align-self: flex-start;

  > form > div {
    margin-top: 0.5rem;
  }
}

.mobileFiltersHeader {
  display: flex;
  align-items: center;
  gap: $space-10;

  & > .H2 {
    color: var(--color-primary);
  }
}
