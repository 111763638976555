.vacancyDetailTopbar {
  display: flex;
  align-items: center;
  padding: $space-20 0;
  gap: $space-10;

  @include md {
    padding: $space-40 0;
  }

  &__btn > span {
    flex-direction: row-reverse;

    & > span > svg {
      transform: rotate(180deg);
    }
  }
}
