/*
  TEXT MIXIN
  If the $size / $lineHeight / $weight arguments are not empty,
  then output the argument.
*/

@mixin text(
  $size: null,
  $lineHeight: null,
  $letter_spacing: null,
  $weight: null
) {
  @if $size != null {
    font-size: pixel-to-rem($size);
  }

  @if $lineHeight != null {
    line-height: pixel-to-rem($lineHeight);
  }

  @if $letter_spacing != null {
    letter-spacing: $letter_spacing;
  }

  @if $weight != null {
    font-weight: $weight;
  }
}

@mixin gradient-text-color($color) {
  @if $color != null {
    background: $color;
  }

  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

/*----------------------------------------------------------------*/

/*
  MEDIA QUERIES MIXINS
  Used to define the viewport width in a media query.
*/

@mixin min-width($threshold) {
  @media screen and (min-width: pixel-to-em($threshold)) {
    @content;
  }
}

// Small devices
@mixin xs($threshold: $screen-xs-min) {
  @media screen and (min-width: #{$threshold}) {
    @content;
  }
}

// Small devices
@mixin sm($threshold: $screen-sm-min) {
  @media screen and (min-width: #{$threshold}) {
    @content;
  }
}

// Medium devices
@mixin md($threshold: $screen-md-min) {
  @media screen and (min-width: #{$threshold}) {
    @content;
  }
}

// Large devices
@mixin lg($threshold: $screen-lg-min) {
  @media screen and (min-width: #{$threshold}) {
    @content;
  }
}

// Extra large devices
@mixin xl($threshold: $screen-xl-min) {
  @media screen and (min-width: #{$threshold}) {
    @content;
  }
}

// XX large devices
@mixin xxl($threshold: $screen-xxl-min) {
  @media screen and (min-width: #{$threshold}) {
    @content;
  }
}

// Custom devices
@mixin customBreakPoint($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

/*----------------------------------------------------------------*/

/*
  MISCELLANEOUS MIXINS
*/
@mixin card {
  border-radius: 20px;
  background-color: $white;
  padding: 15px $space-30;

  @include md {
    padding: $space-40;
  }
}

@mixin dropShadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

@mixin ellipsisText($numOfLines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $numOfLines;
  line-clamp: $numOfLines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
