.ctaVacanciesCard {
  background-image: $rvdb-gradient;
  border-radius: 20px;
  padding: $space-40;
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: 430px;
  max-height: 430px;

  @include md {
    height: 530px;
    max-height: 530px;
  }

  > * {
    color: $white;
  }

  > .P1 {
    max-width: 80ch;
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;

    > .button {
      align-self: flex-end;
    }
  }
}
