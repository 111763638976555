.storyCard {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: $white;
  gap: 29px;
  padding: 20px;

  @include md {
    padding: $space-30 $space-40;
  }

  > .H2 {
    color: $black;
    @include ellipsisText(2);
  }

  > .P1 {
    overflow: hidden;
    @include ellipsisText(3);
  }

  &__img {
    width: 100%;
    border-radius: 20px 20px 0 0;
    overflow: hidden;

    > .imageWrapper {
      width: 100%;

      > span {
        width: 100% !important;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;

    .block {
      height: 40%;
      width: 20%;
    }
  }
}
