.vacancyTag,
.vacancyTagCard {
  color: var(--vacancy-tag-color);
  background-color: var(--vacancy-tag-background-color);
  padding: $space-10 $space-20;
  text-align: center;

  & * {
    line-height: 120%;
  }

  // These are unique colors, not (yet) existing in other places in the app
  &[data-color='new'] {
    --vacancy-tag-color: #055d44;
    --vacancy-tag-background-color: #a5fbe3;
  }

  &[data-color='onHold'] {
    --vacancy-tag-color: #732e08;
    --vacancy-tag-background-color: #fcdece;
  }

  &[data-color='advancedStage'] {
    --vacancy-tag-color: #730840;
    --vacancy-tag-background-color: #fccee6;
  }

  &[data-color='openApplication'] {
    --vacancy-tag-color: #5c5406;
    --vacancy-tag-background-color: #faf3b5;
  }

  &[data-color='modifiedProfile'] {
    --vacancy-tag-color: #245f76;
    --vacancy-tag-background-color: #c7dfe9;
  }

  &[data-color='urgent'] {
    --vacancy-tag-color: #730808;
    --vacancy-tag-background-color: #fccece;
  }
}

.vacancyTag {
  border-radius: 20px;
}

.vacancyTagCard {
  border-radius: 0 20px;
}
