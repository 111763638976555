.carousel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__controls {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 21px;
    margin-top: 75px;

    &-progress {
      width: 100%;
      max-width: 134px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 21px;
    }
  }
}
