.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1280px;
  gap: 15px;

  &__label,
  &__placeholder {
    height: 100%;
    width: 100%;
    font-family: Rubik-Light;
    color: $black;
    @include text(16px, 22px, 0px, 400);

    @include md {
      @include text(18px, 30px, 0px, 400);
    }

    &.required::after {
      content: ' *';
      color: var(--color-primary);
    }
  }

  &__placeholder {
    color: #999999 !important;
    display: flex;
    align-items: center;
  }

  &__container {
    height: 100%;
    width: 100%;

    &:active {
      background-color: $background;
    }
  }

  &__value-container {
    min-height: 50px;
    > * {
      width: fit-content !important;
    }
  }

  &__multi-value__remove {
    color: var(--color-primary);

    &:hover {
      color: $white !important;
      background-color: var(--color-primary) !important;
    }
  }

  &__control {
    &--is-focused {
      border-color: var(--color-primary) !important;
      box-shadow: 0 0 0 1px var(--color-primary) !important;
    }
  }

  &__indicators {
    > button {
      margin: 0 5px;
    }
  }
}
