@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './variables';
@import './mixins';

/* Global */
html,
body {
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-style: normal;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Layouts */

.template {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.main {
  margin-top: $space-50;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 15px;
  width: 100%;
  height: 100%;
  background-color: $background;

  @include lg {
    margin-top: 0;
  }
}

.section {
  max-width: 1280px;
  width: 100%;
}

.nav {
  max-width: 1278px;
}

.bg-gradient {
  background-image: linear-gradient(
    270deg,
    $white 0%,
    $background 50%
  ); /* W3C */
  background-image: -moz-linear-gradient(
    270deg,
    $white 0%,
    $background 50%
  ); /* FF3.6+ */
  background-image: -webkit-linear-gradient(
    270deg,
    $white 0%,
    $background 50%
  ); /* Chrome10+,Safari5.1+ */
}

.bg-gradient--reverse {
  background-image: linear-gradient(
    90deg,
    $white 0%,
    $background 50%
  ); /* W3C */
  background-image: -moz-linear-gradient(
    90deg,
    $white 0%,
    $background 50%
  ); /* FF3.6+ */
  background-image: -webkit-linear-gradient(
    90deg,
    $white 0%,
    $background 50%
  ); /* Chrome10+,Safari5.1+ */
}

/* Fonts */
@font-face {
  font-display: optional;
  font-family: 'Rubik-SemiBold';
  src: url('/fonts/Rubik-SemiBold.woff2');
  font-display: swap;
}
@font-face {
  font-display: optional;
  font-family: 'Rubik-Regular';
  src: url('/fonts/Rubik-Regular.woff2');
  font-display: swap;
}
@font-face {
  font-display: optional;
  font-family: 'Rubik-Light';
  src: url('/fonts/Rubik-Light.woff2');
  font-display: swap;
}
