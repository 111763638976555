.contactInfo {
  display: flex;
  align-items: center;

  &__button {
    padding: 0 $space-5;
    height: auto;
    min-height: 0;
    text-decoration-color: $rvdb-secondary !important;

    & * {
      color: $rvdb-secondary;
    }
  }
}
