.navigationCard {
  display: flex;
  height: 100%;
  max-height: 120px;
  border-radius: 0 20px 20px 0;

  > .imageWrapper {
    flex-basis: 120px;

    & > span {
      min-height: 100%;
      width: 120px;
      border-radius: 20px 0 0 20px;
    }
  }

  &__content {
    width: 100%;
    max-width: 431px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px 20px 15px 40px;
    background-color: $white;
    border: 1px solid $neutral-100;
    border-left: none;
    border-radius: 0 20px 20px 0;

    & > .P1 {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: pre-wrap;
    }
  }
}
