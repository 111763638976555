// Throughout the app, a number of <h#> components are replaced with vanilla HTML <h#> tags, while using a className that handles their stylinh
// This is because of SEO demands from the client, while the initial design was not set up for this.
// The aim is that a page has one <h1> in the banner; section headers are <h2> and subsections / cards are <h3>
// The styling of these elements is determined by their className, allowing them to be styled as needed

.H404 {
  font-family: Rubik-Light;
  color: $background;
  @include text(94px, 100px, 0em, 300);

  @include md {
    @include text(300px, 300px, 0em, 300);
  }
}

.H1s {
  font-family: Rubik-SemiBold;
  @include text(90px, 100px, 0em, 600);
}

.H1 {
  font-family: Rubik-SemiBold;
  @include text(22px, 28px, 0em, 600);

  @include md {
    @include text(40px, 50px, 0em, 600);
  }
}

.H2 {
  font-family: Rubik-Light;
  @include text(22px, 30px, 0em, 300);

  @include md {
    @include text(32px, 38px, 0em, 300);
  }
}

.H3 {
  font-family: Rubik-SemiBold;
  @include text(22px, 30px, 0em, 600);

  @include md {
    @include text(32px, 38px, 0em, 600);
  }
}

.H4 {
  font-family: Rubik-Light;
  @include text(18px, 24px, 0em, 300);
  color: $black;

  @include md {
    @include text(26px, 34px, 0em, 300);
  }
}

.H5 {
  font-family: Rubik-Light;
  @include text(22px, 30px, 0em, 300);
  color: $black;

  @include md {
    @include text(20px, 50px, 0em, 300);
  }
}

.H6 {
  font-family: Rubik-SemiBold;
  @include text(15px, 24px, -0.6px, 300);

  @include md {
    @include text(15px, 24px, -0.2px, 600);
  }
}

.H7 {
  font-family: Rubik-Light;
  @include text(15px, 24px, -0.6px, 300);

  @include md {
    @include text(15px, 24px, -0.2px, 300);
  }
}

.Q1 {
  &--rvdb {
    color: $rvdb-secondary;
  }

  &--ray {
    @include gradient-text-color($ray-gradient);
  }

  font-family: Rubik-SemiBold;
  @include text(30px, 30px, 0em);

  @include md {
    @include text(50px, 56px, 0em);
  }
}

.Q2 {
  font-family: Rubik-SemiBold;
  @include text(30px, 30px, 0em);
  color: var(--color-primary);

  @include md {
    @include text(50px, 50px, 0em);
  }
}

.P1 {
  font-family: Rubik-Regular;
  @include text(16px, 22px, 0em, 400);

  @include md {
    @include text(18px, 30px, 0em, 400);
  }
}

.P2 {
  font-family: Rubik-SemiBold;
  @include text(16px, 22px, 0em, 600);

  @include md {
    @include text(18px, 30px, 0em, 400);
  }
}

.bold {
  font-family: Rubik-SemiBold;
}

.Li {
  list-style: none;
  font-family: Rubik-Regular;
  @include text(16px, 22px, 0em, 400);

  @include md {
    @include text(18px, 30px, 0em, 400);
  }

  &--withStyle {
    display: list-item;
    padding: auto;
  }

  & .P1 {
    margin-bottom: 0;
  }
}

.C1 {
  font-family: Rubik-Regular;
  @include text(14px, 22px, 0em);
  color: $black;
}

.Ul,
.Ol {
  list-style-position: inside;
  padding-inline-start: $space-40;
}

.Ul > .Li {
  list-style-type: disc;
}

.Ol > .Li {
  list-style-type: decimal;
}
