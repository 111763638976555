.profileBanner {
  position: relative;
  border-radius: 20px;
  max-width: 1360px;

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 69%;
    right: 2.5%;
    left: 2.5%;
    width: auto;
    max-width: 730px;
    height: 100%;
    max-height: 187px;
    border-radius: 20px;
    background-color: $white;
    padding: 10px;

    @include sm {
      left: auto;
      align-items: flex-start;
      padding: 40px 50px;
    }

    @include lg {
      width: 100%;
      align-items: flex-start;
    }

    &-heading {
      display: flex;
      align-items: center;
      gap: 20px;

      > .H2 {
        color: $black;
        @include text(32px, 38px, 0px, 300);
      }
    }
  }
}
