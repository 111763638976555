.vacancyDetailContent {
  background-color: $white;
  // padding: 15px $space-30;
  // border-radius: 0 0 20px 20px;

  // @include md {
  //   padding: $space-40;
  // }
}

.vacancyDetailBannerImg {
  display: flex;

  > span {
    width: 100% !important;
    border-radius: 20px 20px 0 0;
  }
}
