.loader {
  width: 100%;
  height: 100%;
  max-height: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiLinearProgress-bar {
  width: 100vw;
}
