.jobsCard {
  display: flex;
  flex-direction: column;
  background-color: $white;
  max-width: 400px;
  height: 300px;
  border-radius: 10px;
  padding: $space-20 $space-20 $space-30 $space-20;

  & > .H3 {
    color: var(--color-primary);
    margin-bottom: $space-20;
  }

  & > .divider {
    margin-top: auto;
  }

  @include md {
    height: 400px;
    border-radius: 20px;
    padding: $space-30 $space-40 $space-30 $space-40;
  }
}
