.textSection {
  &__itemsBlock {
    grid-column: 1 / -1;

    @include lg {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  &__content {
    @include card;
    grid-column: 1 / -1;

    @include lg {
      grid-column-start: 4;
      grid-column-end: 13;
    }
  }
}
