.searchResults {
  z-index: 30;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: $space-20;
  padding: $space-30;
  position: absolute;
  background-color: $white;
  @include dropShadow;

  @include xl {
    padding: $space-50 $space-80;
    grid-template-columns: 1fr 700px 1fr;
  }

  & > .H2 {
    align-self: center;
    display: none;

    @include xl {
      display: block;
    }
  }

  & > .searchResultCard {
    @include xl {
      max-width: 1440px;
      grid-column: 2 / 3;
    }
  }
}
