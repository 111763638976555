.ctaNewsLetterCard {
  display: flex;
  flex-direction: column;
  background-image: $rvdb-gradient;
  border-radius: 20px;
  padding: $space-40 $space-40 $space-30;

  & > .H3 {
    margin-bottom: $space-20;
  }

  & > .P1 {
    @include ellipsisText(4);

    @include md {
      -webkit-line-clamp: 6;
    }
  }

  &__form {
    width: fit-content;
    & * {
      font-family: Rubik-Regular !important;
    }

    & p {
      margin: 0;
    }

    & form {
      margin: 0 !important;
      padding: 0 !important;
    }

    & ._form-content {
      display: flex;
      flex-wrap: wrap;
      gap: $space-10;
      width: 100%;
    }

    & ._field-wrapper,
    ._button-wrapper,
    ._form_element {
      width: fit-content !important;
      margin: 0 !important;
    }

    ._form-thank-you {
      color: white;
      height: 50px;
      line-height: 50px;
      text-align: center;
    }

    & label {
      display: none !important;
    }
  }
}
