.personCard {
  order: var(--order);
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  @include md {
    border-radius: 20px;
  }

  &__img {
    height: 165px;
    border-radius: 20px 20px 0 0;
    overflow: hidden;

    & > span {
      height: 100% !important;
    }
  }

  &__personalia,
  &__contactDetails {
    height: 120px;
    padding: $space-20;
  }

  & > .divider {
    margin: 0 $space-20;
    width: auto;
  }
}
