.primaryItem {
  color: var(--color-primary);

  &:hover {
    text-decoration: underline;
  }
}

.rvdbNavItemLargeWithoutSub {
  &--active,
  &:hover {
    color: var(--color-primary);
  }
}

.rvdbNavLarge {
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: $white;
  height: 152px;
  width: 100%;

  &[data-scrolled='true'] {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  }

  & > div {
    max-width: 1280px;
    display: grid;
    padding: 15px 0;
    gap: 0.75rem;
    grid-auto-rows: 1fr;
    grid-template-areas:
      'logo primary primary primary'
      'logo secondary secondary secondary';
  }

  &__primary {
    grid-area: primary;
    align-self: end;
  }

  &__secondary {
    grid-area: secondary;
  }

  &__logo {
    grid-area: logo;
    align-self: end;
  }
}

.rvdbNavItemSmallWithoutSub {
  display: grid;
  grid-template-areas: 'img link';
  grid-template-columns: 25px 1fr;
  align-items: center;
  padding-bottom: 2.5rem;

  &__img {
    grid-area: img;
  }

  &__link {
    grid-area: link;
  }
}
