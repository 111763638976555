.werkenBijJobsBanner {
  height: 100%;
  border-radius: 20px;
  overflow: hidden;

  & > .imageWrapper {
    position: relative;
    max-height: 100%;

    & > span {
      width: 100% !important;
      max-height: 113px !important;

      @include md {
        max-height: 440px !important;
      }
    }
  }
}
