.ctaRayCard {
  display: flex;
  background: $white;
  border-radius: 20px;
  max-height: 490px;

  &__img {
    display: flex;

    > span {
      border-radius: 0 20px 20px 0;
    }
  }

  &__text {
    padding: $space-40;
  }
}
