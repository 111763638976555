.contactForm {
  & * {
    font-family: Rubik-Regular !important;
    font-size: 16px !important;
  }

  & form {
    max-width: 100% !important;
  }

  & ._form-content {
    width: 100% !important;
    display: grid;
    gap: $space-20;

    @include md {
      grid-template-columns: 1fr 1fr;
    }

    & > ._form_element {
      margin-bottom: 0 !important;

      &:nth-of-type(5) {
        grid-column: 1 / -1;
      }
    }

    & > ._button-wrapper {
      grid-column: 1 / -1;
      width: fit-content !important;
      margin-left: auto !important;
    }
  }
}
