.fileItemCollection {
  & > li {
    display: flex;
    align-items: center;

    & > div {
      cursor: pointer;
    }
  }
}
