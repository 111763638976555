.rcw-item {
  width: 100% !important;
  background-color: $white !important;
  border-radius: 20px !important;
}

.rcw-slider-list-wrapper {
  height: 100% !important;
}

.rcw-loaded {
  padding: 0 !important;
}

.rcw-rating-container {
  width: fit-content !important;
}
