.ctaReviewCard {
  display: flex;
  background: $white;
  border-radius: 20px;

  &__img {
    min-width: 221px;
    display: flex;

    > span {
      border-radius: 20px 0 0 20px;
    }
  }

  &__text {
    padding: $space-40;
    
    & > .H3 {
      @include ellipsisText(5);
      @include text(18px, 24px, 0em, 300);
      @include md {
        @include text(26px, 34px, 0em, 300);
      }
      
      color: $rvdb-secondary;
    }
  }
}
