.slider {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__text {
    display: flex;
    gap: 5px;

    &-range {
      color: $ray-primary;
    }
  }
}

.MuiSlider-rail {
  color: #ccc4c2 !important;
  height: 2px !important;
}

.MuiSlider-track {
  height: 2px !important;
}

.MuiSlider-mark {
  all: initial !important;
}