.navigationDropdownLarge {
  @include dropShadow;
  display: flex;
  width: 100%;
  height: 500px;

  &__item:hover {
    color: var(--color-primary);
  }

  &__leftBlock {
    background-color: $background;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 40px 60px;
    max-width: 370px;
    width: 100%;
  }

  &__middleBlock {
    background-color: $white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 60px;
    max-width: 670px;
    width: 100%;
  }

  &__rightBlock {
    background-color: $white;
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding: 40px 30px;
    max-width: 400px;
    width: 100%;

    &__social {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      max-width: 191px;
    }

    &-links {
      display: flex;
      flex-direction: column;
    }

    > button {
      margin-top: 15px;
      max-width: 241px;
    }
  }
}
