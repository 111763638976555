.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: fit-content;
  grid-column: 1 / -1;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--color-primary);
}

.singleTab {
  display: grid;
  padding: $space-10;
  place-items: center;
  flex: 1;
  min-height: 60px;
  min-width: 130px;
  margin: -1px;

  &--active,
  &--inactive {
    border: 1px solid var(--color-primary);
    background-color: $white;

    & > .P1 {
      text-align: center;
    }
  }

  &--active {
    background-color: var(--color-primary);

    & > .P1 {
      color: $white;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.mobileTabs__filterText {
  color: var(--color-primary);
}
