.globalSearch {
  width: 100%;

  @include xl {
    width: 500px;
  }

  &__formEl {
    width: 100%;
  }

  &__icon {
    display: flex;
    align-items: center;
    height: 100%;
    width: fit-content;
    margin-right: $space-20;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }
}
