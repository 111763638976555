.footer {
  width: 100%;
  padding: pixel-to-rem(89px) 1rem pixel-to-rem(26px) 1rem;
  position: relative;
  background-color: $background;

  &__wrapper {
    margin: auto;
    width: 100%;
    max-width: 1280px;
    display: grid;
    gap: 20px;
    grid-template-columns: auto;
    grid-template-areas:
      'newsletter newsletter'
      'newsletter newsletter'
      'contact-details contact-details'
      'links links'
      'legal legal'
      'logo logo';

    @include sm {
      grid-template-columns: auto auto;
      grid-template-areas:
        'newsletter newsletter'
        'newsletter newsletter'
        'contact-details links'
        'contact-details links'
        'logo legal'
        'logo legal';
    }

    @include lg {
      grid-template-columns: auto 15% auto auto auto auto;
      grid-template-areas:
        'newsletter . contact-details links legal scroll-up'
        'logo       . contact-details links legal scroll-up';
    }
  }

  &__logo {
    grid-area: logo;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-top: 20px;
  }

  &__newsletter {
    grid-area: newsletter;
    display: flex;
    flex-direction: column;
    width: auto;

    @include sm {
      max-width: 400px;
    }

    > .H2 {
      @include lg {
        max-width: 12ch;
      }
    }

    & div {
      text-align: left !important;
    }

    & form & * {
      font-family: Rubik-Regular !important;
    }

    & ._form_element {
      width: fit-content !important;
      margin: 0 1rem 1rem 0 !important;
    }

    & ._button-wrapper {
      width: fit-content !important;
      margin: 0 !important;
    }

    & label {
      display: none !important;
    }
  }

  &__contact-details {
    grid-area: contact-details;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    margin-top: 20px;
  }

  &__address {
    display: flex;
    flex-direction: column;
    grid-gap: 26px;
  }

  &__links {
    grid-area: links;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 3px;
    flex-grow: 1;
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    max-width: 191px;
  }

  &__legal {
    grid-area: legal;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 3px;
  }

  &__scroll-up {
    visibility: hidden;
    min-width: 50px;

    @include lg {
      grid-area: scroll-up;
      visibility: visible;
      justify-self: right;
      align-self: start;
      margin-top: 20px;
    }
  }
}
