.vacancyCard {
  position: relative;
  display: flex;
  @include card;
  order: var(--order);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 430px;
  max-height: 430px;

  & > .vacancyTagCard {
    position: absolute;
    top: 0;
    right: 0;
  }

  @include md {
    height: 530px;
    max-height: 530px;
  }

  &__description {
    margin-top: $space-20;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-title {
      @include ellipsisText(2);
      margin-bottom: $space-10;
    }

    &-text {
      @include ellipsisText(6);
      margin-top: $space-10;

      @include md {
        @include ellipsisText(4);
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;

    > :first-child {
      width: 1.5rem;
    }
  }

  &__divider {
    margin-top: auto;
  }

  &__cta {
    display: flex;
    align-items: center;
    width: 100%;

    > .button {
      align-self: flex-end;
    }
  }
}
