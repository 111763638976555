.standardCard {
  display: flex;
  flex-direction: column;
  padding: $space-40 $space-20 $space-30;
  background-color: $background;
  border-radius: 20px;
  height: 307px;

  & > .P1 {
    @include ellipsisText(4);

    @include md {
      -webkit-line-clamp: 6;
    }
  }

  @include md {
    height: 400px;
    padding: $space-40 $space-30 $space-30;
  }
}
